import { get, post } from '@/libs/axios'

// 分页获取隐私字段标签列表
export const LabelPage = params => post('/field/label/page', params)
// 创建标签
export const saveOrUpdateLabel = form => {
  const submitUrl = form.id ? '/field/label/update' : '/field/label/create'
  return post(submitUrl, form)
}
// 删除标签
export const DeleteLabelById = params => post('/field/label/delete', params)
// 标签下拉列表
export const GetLabelOptions = () => get('/field/label/options')
