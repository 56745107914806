<template>
  <div>
    <b-row
        :gutter="20"
        class="match-height"
    >
      <b-col md="3">
        <b-card>
          <div class="flex-between mb10">
            <h4>
              标签维度
            </h4>
            <el-button
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="openClassSaveDialog(1,null)"
            >
              新增一级维度
            </el-button>
          </div>
          <div :style="{maxHeight: pageH -250 + 'px',overflowY: 'scroll'}">
            <el-tree
                ref="elTree"
                :highlight-current="true"
                :data="treeData"
                node-key="id"
                :expand-on-click-node="false"
                @node-click="treeNodeClick"
            >
              <span
                  slot-scope="{ node, data }"
                  class="field-tree-node"
              >
                <span>{{ data.name }}</span>
                <span class="eltree-operate tree-operate">
                  <el-link
                      v-show="node.level < 4"
                      :underline="false"
                      class="mr10"
                      type="primary"
                      @click.stop="openClassSaveDialog(1,data)"
                  >
                    <el-tooltip
                        content="新增"
                        placement="top"
                        effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                      :underline="false"
                      class="mr10"
                      type="primary"
                      @click.stop="openClassSaveDialog(2,data)"
                  >
                    <el-tooltip
                        content="修改"
                        placement="top"
                        effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                      :underline="false"
                      type="primary"
                      @click.stop="deleteTree(data,node)"
                  >
                    <el-tooltip
                        content="删除"
                        placement="top"
                        effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card>
          <div class="filter-wrap flex-between">
            <SearchFilter
                :searchStr.sync="listQuery.SearchStr"
                @handleFilter="handleFilter"
            />
            <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="saveDialogShow(1)"
            >
              新增
            </el-button>
          </div>
          <el-table
              v-loading="loading"
              fit
              border
              highlight-current-row
              :data="tableData"
              stripe
              style="width: 100%"
          >
            <el-table-column
                :show-overflow-tooltip="true"
                prop="labelName"
                label="标签名称"
            />
            <el-table-column
                :show-overflow-tooltip="true"
                prop="dimensionName"
                label="标签维度"
            />
            <el-table-column
                :show-overflow-tooltip="true"
                prop="remark"
                label="备注"
            />
            <el-table-column
                label="操作"
                width="80px"
            >
              <template slot-scope="{row}">
                <el-link
                    class="mr10"
                    type="primary"
                    @click="saveDialogShow(2,row)"
                >
                  <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                    type="primary"
                    @click="del(row)"
                >
                  <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <pagination
              v-show="total>0"
              :total="total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.pageSize"
              @pagination="getList"
          />
        </b-card>
      </b-col>
    </b-row>
    <LabelEdit
        ref="labelDialog"
        @handleFilter="handleFilter"
    />
    <DimensionEdit
        ref="dimensionDialog"
        @addTreeNode="addTreeNode"
        @updateTreeNode="updateTreeNode"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import DimensionEdit from '@/views/field/dimensionEdit.vue'
import LabelEdit from '@/views/field/labelEdit.vue'
// eslint-disable-next-line import/no-cycle
import {
  DeleteLabelById, LabelPage,
} from '@/api/field/label'
// eslint-disable-next-line import/no-cycle
import { error, success } from '@core/utils/utils'

// eslint-disable-next-line import/no-cycle
import { GetLabelDimensionTree, DeleteDimensionById } from '@/api/field/labelDimension'

import SearchFilter from '@core/components/search-filter/SearchFilter.vue'

export default {
  components: {
    pagination,
    DimensionEdit,
    LabelEdit,
    SearchFilter,
  },

  data() {
    return {
      detailShow: false,
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        dimensionId: 0,
        SearchStr: '',
      },
      tableData: [],
      treeData: [],
      optClassData: {},
    }
  },
  created() {
    this.getAllTree()
    this.getList()
  },
  methods: {
    getAllTree() {
      GetLabelDimensionTree().then(res => {
        const resData = res.data
        if (resData.code === 0 && resData.data && resData.data.length > 0) {
          this.treeData = resData.data
        }
      })
    },
    deleteTree(data, node) {
      this.$confirm('是否确认删除该维度吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteDimensionById({ id: data.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            const { parent } = node
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            success(res.data.msg)
            if (this.listQuery.dimensionId === data.id) {
              this.listQuery.dimensionId = 0
              this.handleFilter()
            }
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    treeNodeClick(data, node) {
      if (this.listQuery.dimensionId === data.id) {
        this.listQuery.dimensionId = 0
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.listQuery.dimensionId = data.id
      }
      this.handleFilter()
    },
    addTreeNode(data) {
      if (data.parentId === 0) {
        this.treeData.push(data)
      } else {
        if (!this.optClassData.children) {
          this.$set(this.optClassData, 'children', [])
        }
        this.optClassData.children.push(data)
      }
    },
    updateTreeNode() {
      this.getAllTree()
      this.handleFilter()
    },
    openClassSaveDialog(type, data) {
      this.$refs.dimensionDialog.dialogVisible = true
      if (type === 2) {
        this.$refs.dimensionDialog.form.id = data.id
        this.$refs.dimensionDialog.form.parentId = data.parentId
        this.$refs.dimensionDialog.form.dimensionName = data.label
      } else if (data) {
        this.$refs.dimensionDialog.form.parentId = data.id
        this.$refs.dimensionDialog.form.parentName = data.label
      } else {
        this.$refs.dimensionDialog.form.parentId = 0
      }
      this.optClassData = data
    },
    del(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteLabelById({ id: row.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    saveDialogShow(type, row) {
      this.$refs.labelDialog.dialogVisible = true
      if (type === 1) {
        this.$refs.labelDialog.form.id = ''
      } else {
        this.$refs.labelDialog.form.id = row.id
        this.$refs.labelDialog.form.labelName = row.labelName
        this.$refs.labelDialog.form.dimensionId = row.dimensionId
        this.$refs.labelDialog.form.remark = row.remark
      }
    },
    getList() {
      this.loading = true
      LabelPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },

}
</script>

<style lang="scss">
.field-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
.tree-operate {
  position: absolute;
  right: 0px;
}
</style>
