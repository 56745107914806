import { get, post } from '@/libs/axios'

// 标签维度
// 创建/修改标签维度
export const saveOrUpdateDimension = form => {
  const submitUrl = form.id ? '/privacyField/labelDimension/UpdateDimension' : '/privacyField/labelDimension/CreateDimension'
  return post(submitUrl, form)
}
// 删除标签维度
export const DeleteDimensionById = params => post('/label/dimension/delete', params)

// 标签维度树
export const GetLabelDimensionTree = () => get('/label/dimension/tree')
